import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12ZM12 14C14.7614 14 17 11.7614 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 11.7614 9.23858 14 12 14Z"
      />
      <path d="M6 19C6 17.8954 6.89543 17 8 17H16C17.1046 17 18 17.8954 18 19V20.5C18 20.7761 18.2239 21 18.5 21H19.5C19.7761 21 20 20.7761 20 20.5V19C20 16.7909 18.2091 15 16 15H8C5.79086 15 4 16.7909 4 19V20.5C4 20.7761 4.22386 21 4.5 21H5.5C5.77614 21 6 20.7761 6 20.5V19Z" />
    </svg>
  );
};

export default Icon;
