import { FC } from "react";

import { useAuth } from "@contexts/auth";
import { useHighlightedTextContext } from "@contexts/highlighted";
import useSnackbar from "@hooks/use-snackbar";
import { GroupMemberType, GroupType } from "@lib/shared-types";

import { HighlightedTextFromContext } from "@components/HighlightedText";
import UserItemList from "@components/UserItemList";

import MemberSubtitle from "./MemberSubtitle";
import { updateGroup } from "./utils";

export const handleGroupArchive = async (
  groupId: string,
  action: string,
  coachId: string,
  callback?: () => void
): Promise<Partial<GroupType>> => {
  const { data } = await updateGroup(groupId, { status: action }, coachId);
  if (callback) {
    callback();
  }
  return data;
};

interface GroupsListProps {
  groups: GroupType[] | undefined;
  canEdit: boolean;
  showMembersFilter: boolean;
}

const GroupsList: FC<GroupsListProps> = ({ groups, canEdit }) => {
  const snackbar = useSnackbar();
  const { oid } = useAuth();
  const deleteGroup = async (groupId: string) =>
    await updateGroup(groupId, { status: "deleted" }, oid!);

  const onUndoDelete = async (undoStatus: string, groupId: string) =>
    await updateGroup(groupId, { status: undoStatus }, oid!);

  const onGroupActiveChange = async ({
    id,
    activity,
  }: {
    id: string;
    activity: string;
  }) => {
    const wasActive = activity === "inactive";
    const groupTitle = groups?.find((group: GroupType) => group.id === id)
      ?.title;
    const callback = () =>
      snackbar.showMessage(
        `Group ${wasActive ? "archived" : "unarchived"}`,
        groupTitle,
        "Undo",
        () =>
          updateGroup(id, { status: wasActive ? "active" : "inactive" }, oid!)
      );
    await handleGroupArchive(id, activity, oid!, callback);
  };

  const { highlight } = useHighlightedTextContext();

  return (
    <div className="mt-6">
      <UserItemList
        showSearch
        artifactType="group"
        collection="groups"
        items={groups}
        onDelete={canEdit ? deleteGroup : undefined}
        onUndoDelete={onUndoDelete}
        undoStatus="active"
        onActiveChange={canEdit ? onGroupActiveChange : undefined}
        itemTitle={({ title }) => (
          <HighlightedTextFromContext text={title} highlight={highlight} />
        )}
        itemSubtitle={(group) => (
          <MemberSubtitle
            showAvatars
            color={group.color}
            members={group.members as Array<GroupMemberType>}
          />
        )}
        showEdit={canEdit}
        showDuplicate={canEdit}
        clickGoesToView
        showMoreInfoButton={canEdit}
        showCreateIfEmpty={canEdit}
      />
    </div>
  );
};

export default GroupsList;
