import { useState } from "react";
import axios from "axios";

import {
  UpdateContactPayload,
  useUpdateContactsCache,
} from "@hooks/useUpdateContactsCache";
import analytics from "@lib/analytics";
import { ClientStatusEnumType } from "@lib/data/schemas/client";

type UseContactBatchArchiveType = (props: {
  userId: string;
  mutate?: (cache?: any) => void;
}) => {
  loading: boolean;
  error: string | null;
  update: UseContactBatchArchiveUpdateType;
  clearError: () => void;
};

type UseContactBatchArchiveUpdateType = ({
  contactIds,
  status,
}: {
  contactIds: string[];
  status: string;
}) => Promise<void>;

const useContactBatchArchive: UseContactBatchArchiveType = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { updateCache } = useUpdateContactsCache();

  const clearError = () => setError(null);

  const update: UseContactBatchArchiveUpdateType = async ({
    contactIds,
    status,
  }) => {
    try {
      clearError();
      setLoading(true);
      const updates: UpdateContactPayload[] = [];
      for (let i = 0; i < contactIds?.length; i++) {
        await axios.put(`/api/v1/users/${userId}/clients/${contactIds[i]}`, {
          status,
        });
        updates.push({
          contactId: contactIds[i],
          data: { status: status as ClientStatusEnumType },
        });
      }
      analytics.track({
        event: "client_list_archive_batch",
        userId,
      });
      updateCache(updates);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error.response?.data);
      } else if (error instanceof Error) {
        setError(error.message);
      }
      console.log("useContactBatchArchive error:", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    update,
    clearError,
  };
};

export default useContactBatchArchive;
