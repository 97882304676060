import React, { FC, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Link from "next/link";

import CopyLinkIconButton from "@components/Buttons/CopyLinkIconButton";
import DuplicateIcon from "@components/Icons/DuplicateIcon";
import EmbedIcon from "@components/Icons/EmbedIcon";
import MailIcon from "@components/Icons/MailIcon";
import MoreIcon from "@components/Icons/MoreIcon";
import PencilIcon from "@components/Icons/PencilIcon";
import TrashIcon from "@components/Icons/TrashIcon";
import ViewOnIcon from "@components/Icons/ViewOnIcon";
import Transition from "@components/Transition";

import Toggle from "./Form/Toggle";
import DeleteModal from "./Modals/DeleteModal";
import FloatingTooltipWrapper from "./Tooltips/FloatingTooltipWrapper";
import TypeIllustration from "./TypeIllustration";

type ContentBoxProps = {
  icon?: React.ReactNode;
  iconFileType?: string;
  topText?: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  id: string;
  editLink?: string;
  onClickEdit?: () => void;
  copyLink?: string;
  type: string;
  previewLink?: string;
  status?: string;
  highlighted?: boolean;
  onSendToClient?: () => void;
  onDelete?: (id: string) => void;
  isDeleteActionDisabled?: boolean;
  onUndoDelete?: (status: string, id?: string) => Promise<void>;
  undoStatus?: string;
  onDuplicate?: () => void;
  onActiveChange?: (params: { id: string; activity: string }) => void;
  secret?: boolean;
  fade?: boolean;
  variant?: string;
  dialogDeleteTitle?: string;
  dialogSubtitle?: string;
  onEmbeddedPreview?: () => void;
  artifactViewLink?: string;
  showMoreInfoButton?: boolean;
  onClick?: () => void;
  showSnackbar?: boolean;
  tooltip?: string;
  titleClassName?: string;
  rightActionSection?: React.ReactNode;
};

const ContentBox: FC<ContentBoxProps> = ({
  icon,
  iconFileType,
  topText,
  title,
  subtitle,
  id,
  editLink,
  onClickEdit,
  copyLink,
  type,
  previewLink,
  status = "",
  highlighted,
  onSendToClient,
  onDelete,
  isDeleteActionDisabled = false,
  onUndoDelete,
  undoStatus,
  onDuplicate,
  onActiveChange,
  onClick,
  fade,
  variant,
  dialogDeleteTitle,
  dialogSubtitle,
  onEmbeddedPreview,
  artifactViewLink,
  showMoreInfoButton = true,
  showSnackbar = true,
  tooltip,
  titleClassName,
  rightActionSection,
}) => {
  const [isMoreInfoModalOpened, setIsMoreInfoModalOpened] = useState(false);
  const [isDeleteConfirmationModalOpened, setIsDeleteConfirmationModalOpened] =
    useState(false);
  const moreInfoRef = useRef<HTMLDivElement>(null);
  const isGroup = type === "Group";

  const showMoreInfo = (e) => {
    if (e.which === 3) return;
    if (!moreInfoRef.current?.contains(e.target)) {
      setIsMoreInfoModalOpened(false);
      return;
    }
  };

  const isActive = status === "active";

  const boxClick = (e) => {
    if (e.nativeEvent.which === 3) setIsMoreInfoModalOpened(true);
  };

  const boxClassName = classNames(
    "flex-1 flex items-start h-26 justify-between rounded-md truncate",
    fade && "h-8 bg-gradient-to-t from-white",
    isActive &&
      "bg-grey-950 hover:bg-action-900 focus:bg-action-700 active:bg-action-700",
    !isActive && "border-dashed border border-grey-900 hover:bg-grey-950",
    highlighted && "bg-action-800"
  );

  const renderSubtitle = (
    <div className="text-grey-500 font-regular text-sm text-left pt-2 mr-16 truncate">
      {isActive
        ? subtitle
        : isGroup
        ? "Archived"
        : status?.charAt(0)?.toUpperCase() + status?.slice(1)}
    </div>
  );

  useEffect(() => {
    document.addEventListener("mouseup", showMoreInfo);
    Array.from(document.getElementsByClassName("contentbox")).map((box) =>
      box.addEventListener("contextmenu", (e) => e.preventDefault())
    );

    return () => {
      document.removeEventListener("mouseup", showMoreInfo);
      Array.from(document.getElementsByClassName("contentbox")).map((box) =>
        box.removeEventListener("contextmenu", (e) => e.preventDefault())
      );
    };
  }, []);

  const handleActive = () =>
    isActive
      ? onActiveChange?.({ id, activity: "inactive" })
      : onActiveChange?.({ id, activity: "active" });

  const getItemActionClassname = (disabled?: boolean) => {
    return classNames(
      "flex justify-between items-center w-full text-left px-4 py-2 leading-5 text-black-ink hover:bg-gray-100 focus:outline-none focus:bg-gray-100",
      disabled && "pointer-events-none text-grey-800"
    );
  };

  const renderEditButton = () => {
    if (!editLink && !onClickEdit) return null;

    const content = (
      <>
        Edit
        <PencilIcon />
      </>
    );

    return editLink ? (
      <Link href={editLink} className={getItemActionClassname()}>
        {content}
      </Link>
    ) : (
      <button onClick={onClickEdit} className={getItemActionClassname()}>
        {content}
      </button>
    );
  };

  const renderDuplicateButton = onDuplicate && (
    <button onClick={onDuplicate} className={getItemActionClassname()}>
      Duplicate
      <DuplicateIcon />
    </button>
  );

  const renderSendToClientButton = onSendToClient && isActive && (
    <button
      type="button"
      onClick={onSendToClient}
      className={getItemActionClassname(!isActive)}
    >
      Send to client
      <MailIcon />
    </button>
  );

  const renderPreviewButton = previewLink && isActive && (
    <a
      href={previewLink}
      target="_blank"
      rel="noreferrer"
      className={getItemActionClassname(!isActive)}
    >
      View as client
      <ViewOnIcon />
    </a>
  );

  const renderEmbedButton = onEmbeddedPreview && (
    <button onClick={onEmbeddedPreview} className={getItemActionClassname()}>
      <div>Embed</div>
      <EmbedIcon />
    </button>
  );

  const renderToggleActiveButton = onActiveChange && (
    <div
      data-heap-event-name={
        isActive ? type.toLowerCase() + "_deactivated" : "_activated"
      }
      className="flex justify-between items-center w-full text-left p-4 border-b border-grey-950 leading-5 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
    >
      {isActive ? "Active" : isGroup ? "Archived" : "Inactive"}
      <Toggle onChange={handleActive} value={isActive} />
    </div>
  );

  const renderDeleteButton = onDelete && (
    <button
      type="button"
      data-heap-event-name={type.toLowerCase() + "_deleted"}
      onClick={() => {
        setIsMoreInfoModalOpened(false);
        setIsDeleteConfirmationModalOpened(true);
      }}
      className="flex justify-between items-center w-full text-left p-4 border-t border-grey-950 leading-5 text-peach-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
    >
      Delete
      <TrashIcon />
    </button>
  );

  const content = (
    <div className="flex-1 truncate">
      <button className="inline-block p-4 w-full h-full focus:outline-none">
        <div className="mb-1 flex">
          {topText && (
            <div
              className={classNames(
                !isActive && "filter grayscale",
                fade && "bg-gradient-to-t from-white opacity-25"
              )}
            >
              {topText}
            </div>
          )}
          {icon && !topText && (
            <>
              {typeof icon === "string" ? (
                <TypeIllustration
                  slug={icon}
                  fileType={iconFileType}
                  className={classNames(
                    "w-7 h-7",
                    !isActive && "filter grayscale",
                    fade && "bg-gradient-to-t from-white opacity-25"
                  )}
                />
              ) : (
                <div>{icon}</div>
              )}
            </>
          )}
        </div>
        <div>
          <div
            className={classNames(
              "text-black-ink font-medium truncate text-left",
              !isActive && "opacity-40",
              titleClassName
            )}
          >
            {title}
          </div>
          {renderSubtitle}
        </div>
      </button>
    </div>
  );

  return (
    <div className="relative inline-block contentbox">
      <FloatingTooltipWrapper text={tooltip ?? ""} enabled={!!tooltip}>
        <div
          className={classNames("box-content", fade ? "h-5" : "h-full")}
          onMouseDown={(e) => boxClick(e)}
        >
          <div className="col-span-1 flex rounded-md h-full relative">
            <div className={boxClassName}>
              {onClick ? (
                <div className="flex-1 truncate" onClick={onClick}>
                  {content}
                </div>
              ) : (
                <Link
                  href={fade ? "" : artifactViewLink || editLink}
                  legacyBehavior
                >
                  {content}
                </Link>
              )}
            </div>
            {!fade && showMoreInfoButton && (
              <div
                ref={moreInfoRef}
                className="shrink-0 pr-4 py-3 self-end flex absolute bottom-0 right-0 items-center"
              >
                <button
                  onClick={() =>
                    setIsMoreInfoModalOpened(!isMoreInfoModalOpened)
                  }
                  className="w-8 h-8 inline-flex items-center justify-center text-gray-500 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                >
                  <MoreIcon className="text-grey-500" />
                </button>
                {isActive && copyLink && (
                  <CopyLinkIconButton
                    link={copyLink}
                    onCopy={() => setIsMoreInfoModalOpened(false)}
                  />
                )}
                {rightActionSection}
              </div>
            )}
          </div>
        </div>
        <Transition
          show={isMoreInfoModalOpened}
          className="z-50 origin-top-right absolute top-0 right-0 mt-24 w-56 rounded-md shadow-lg"
        >
          <div className="rounded-md bg-white ring-1 ring-black/5">
            <div
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {renderToggleActiveButton}
              {renderEditButton()}
              {renderDuplicateButton}
              {isActive && renderSendToClientButton}
              {isActive && renderPreviewButton}
              {variant === "appointment" && isActive && renderEmbedButton}
              {renderDeleteButton}
            </div>
          </div>
        </Transition>
        <DeleteModal
          show={isDeleteConfirmationModalOpened}
          showSnackbar={showSnackbar}
          toggleShow={setIsDeleteConfirmationModalOpened}
          onDelete={() => onDelete?.(id)}
          onUndoDelete={onUndoDelete}
          undoStatus={undoStatus}
          artifactType={type.toLowerCase()}
          isActionDisabled={isDeleteActionDisabled}
          title={dialogDeleteTitle}
          description={dialogSubtitle}
          disableRouteBack
          artifactId={id}
        />
      </FloatingTooltipWrapper>
    </div>
  );
};

export default ContentBox;
