import { FC } from "react";
import dynamic from "next/dynamic";

import ContactIcon from "@components/Icons/ContactIcon";

interface ClientsIllustrationEmptyStateProps {
  className?: string;
}

const IllustrationEmptyState = dynamic(
  () => import("@components/EmptyState/IllustrationEmptyState")
);

const ClientsIllustrationEmptyState: FC<ClientsIllustrationEmptyStateProps> = ({
  className,
}) => {
  return (
    <IllustrationEmptyState
      title="Easily remember client details"
      className={className}
      description="Manage information across all your client records and understand more about your client with custom fields."
      categoryName="contacts"
      primaryButton={{
        text: "Customize template",
        icon: <ContactIcon className={undefined} />,
        href: "/contacts/template",
      }}
      dismissKey="clientsIllustrationEmptyState"
      videoName="clients"
    />
  );
};

export default ClientsIllustrationEmptyState;
