import dynamic from "next/dynamic";

import { ListSearchBar } from "@components/SearchBar";

interface SectionActionCardsProps {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  className?: string;
  memberFilter?: JSX.Element;
  SortDropdown?: JSX.Element;
  showSearch?: boolean;
  type?: string;
  isEmpty?: boolean;
}

const HelpEmptyState = dynamic(
  () => import("@components/EmptyState/HelpEmptyState")
);

const SectionActionCards: React.FC<SectionActionCardsProps> = ({
  children,
  title,
  subtitle,
  className,
  memberFilter,
  SortDropdown,
  showSearch,
  isEmpty,
  type,
}) => {
  const showEmptyState =
    isEmpty && type && ["schedulers", "forms"].includes(type);
  return (
    <section className={className} id="section-action-cards">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-black-ink text-xl font-regular items-center">
            {title}
          </h2>
          {subtitle && <h3 className="text-grey-500">{subtitle}</h3>}
        </div>
        {showSearch && <ListSearchBar />}
        <div className="ml-8 flex flex-col space-y-2 items-end sm:flex-row sm:space-y-0 sm:items-center space-x-0 sm:space-x-4">
          {memberFilter}
          {SortDropdown}
        </div>
      </div>
      {showEmptyState && <HelpEmptyState type={type} />}
      <div className="mt-6 grid grid-cols-1 gap-3 sm:gap-4 sm:grid-cols-2 md:grid-cols-3">
        {children}
      </div>
    </section>
  );
};

export default SectionActionCards;
