import classNames from "classnames";

import ChevronClosed from "./Icons/DoubleChevronCloseIcon";
import Chevron from "./Icons/DoubleChevronOpenIcon";

interface Props {
  handleSetCollapsed: (collapsed: boolean) => void;
  slice: Array<number>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  renderType: (item: object, fade: boolean) => void;
  collapsed: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shownArray: any[];
  type: string;
}

const TypecardsCollapse: React.FC<Props> = ({
  handleSetCollapsed,
  slice,
  renderType,
  collapsed,
  shownArray,
  type,
}) => {
  const renderBorder = () => (
    <div className="w-full h-5 bg-gradient-to-t from-white border-dashed border-t rounded-tr-md rounded-tl-md border-r border-l border-grey-900 hover:bg-grey-950"></div>
  );
  const label = type === "appointments" ? "schedulers" : type;
  return (
    <div className="group">
      {collapsed && (
        <div
          onClick={() => handleSetCollapsed(false)}
          className="mt-3 sm:mt-4 grid grid-cols-1 gap-3 sm:gap-4 sm:grid-cols-2 md:grid-cols-3"
        >
          {shownArray
            ?.slice(slice[2], slice[3])
            .map((item) =>
              item.status === "inactive"
                ? renderBorder()
                : renderType(item, true)
            )}
        </div>
      )}
      {collapsed && shownArray?.length > slice[1] ? (
        <div className="flex w-full">
          <a
            className={classNames(
              shownArray.length === 6 &&
                slice[1] === 6 &&
                "group-hover:invisible",
              "flex cursor-pointer pt-4 text-grey-500 visible w-full justify-center"
            )}
            onClick={() => handleSetCollapsed(false)}
          >
            See all {label} <Chevron />
          </a>
        </div>
      ) : (
        shownArray?.length > slice[1] && (
          <div className="flex w-full pt-8">
            <a
              className="text-grey-500 w-full justify-center flex cursor-pointer"
              onClick={() => handleSetCollapsed(true)}
            >
              See fewer {label} <ChevronClosed />
            </a>
          </div>
        )
      )}
    </div>
  );
};

export default TypecardsCollapse;
