import { FC } from "react";
import dynamic from "next/dynamic";

import ClientIcon from "@components/Icons/ClientIcon";
import DayIcon from "@components/Icons/DayIcon";
import GroupIcon from "@components/Icons/GroupIcon";
import NotificationIcon from "@components/Icons/NotificationIcon";
import ThunderIcon from "@components/Icons/ThunderIcon";

interface ClientsIntegrationEmptyStateProps {
  className?: string;
}

const IntegrationEmptyState = dynamic(
  () => import("@components/EmptyState/IntegrationEmptyState")
);

const ClientsIntegrationEmptyState: FC<ClientsIntegrationEmptyStateProps> = ({
  className,
}) => {
  return (
    <IntegrationEmptyState
      className={className}
      icon={
        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-action-900 text-action-500">
          <ClientIcon />
        </div>
      }
      title="Bring your contacts to Practice"
      description="A complete view of all client relationships enabling you to stay organized."
      primaryButton={{
        text: "Import clients",
        href: "/contacts/import",
      }}
      secondaryButton={{
        text: "Create a test client",
        href: "/contacts/create",
      }}
      infoLines={[
        {
          icon: DayIcon,
          text: "Keep track of every client detail and interaction",
        },
        {
          icon: ThunderIcon,
          text: "Manage and automate your client records",
        },
        { icon: GroupIcon, text: "Individuals and groups in one place" },
        {
          icon: NotificationIcon,
          text: "We never contact your clients without consent",
        },
      ]}
    />
  );
};

export default ClientsIntegrationEmptyState;
