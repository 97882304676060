import { FC } from "react";
import classNames from "classnames";

import useCopyToClipboard from "@hooks/use-copy-to-clipboard";
import { getCurrentURIFromServer } from "@lib/utils";

import { Button } from "@components/Button";
import LinkedIcon from "@components/Icons/LinkedIcon";
import Menu from "@components/Menu/Menu";

interface CopyLinkIconButtonProps {
  className?: string;
  iconClassName?: string;
  link: string;
  isHref?: boolean;
  onCopy?: () => void;
}

const CopyLinkIconButton: FC<CopyLinkIconButtonProps> = ({
  className,
  iconClassName,
  link,
  isHref = false,
  onCopy,
}) => {
  const url = isHref ? `${getCurrentURIFromServer()}${link}` : link;
  const { copyToClipboard, isCopyLabelVisible } = useCopyToClipboard();

  return (
    <div className="relative">
      <Button
        className={classNames(className)}
        variant="text"
        smaller
        square
        icon={
          <LinkedIcon
            className={classNames("w-5 h-5 text-grey-500", iconClassName)}
          />
        }
        onClick={() => {
          copyToClipboard(url);
          onCopy && onCopy();
        }}
        data-heap-event-name="link_copied"
      />
      <Menu
        show={isCopyLabelVisible}
        className="bg-black-ink py-2 px-3 absolute text-white rounded-md text-xs bottom-8 right-0 whitespace-nowrap"
      >
        Link copied
      </Menu>
    </div>
  );
};

export default CopyLinkIconButton;
