import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className, width = 24, height = 24 }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6667 21.3333C17.6122 21.3333 20 18.9455 20 16C20 13.0545 17.6122 10.6666 14.6667 10.6666C11.7211 10.6666 9.33333 13.0545 9.33333 16C9.33333 18.9455 11.7211 21.3333 14.6667 21.3333ZM14.6667 18.6666C16.1394 18.6666 17.3333 17.4727 17.3333 16C17.3333 14.5272 16.1394 13.3333 14.6667 13.3333C13.1939 13.3333 12 14.5272 12 16C12 17.4727 13.1939 18.6666 14.6667 18.6666Z"
      />
      <path d="M9.33333 24C9.33333 23.2636 9.93029 22.6666 10.6667 22.6666H18.6667C19.403 22.6666 20 23.2636 20 24V25.3333H9.33333V24Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.33331V2.66665C8 1.93027 8.59695 1.33331 9.33333 1.33331H28C28.7364 1.33331 29.3333 1.93027 29.3333 2.66665V25.3333C29.3333 26.0697 28.7364 26.6666 28 26.6666H25.3333V29.3333C25.3333 30.0697 24.7364 30.6666 24 30.6666H5.33333C4.59695 30.6666 4 30.0697 4 29.3333V6.66665C4 5.93027 4.59695 5.33331 5.33333 5.33331H8ZM10.6667 5.33331V3.99998H26.6667V24H25.3333V6.66665C25.3333 5.93027 24.7364 5.33331 24 5.33331H10.6667ZM6.66667 28V7.99998H22.6667V28H6.66667Z"
      />
    </svg>
  );
};

export default Icon;
