import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { omit } from "lodash";

import { compatDB as db } from "@lib/firebase-config";

interface Props {
  collectionName: string;
  id: string;
  uid: string;
}

export const duplicateArtifact = async ({
  collectionName,
  id,
  uid,
}: Props): Promise<string | undefined> => {
  const docRef = doc(db, `users/${uid}/${collectionName}/${id}`);
  const docData = await getDoc(docRef)
    .then((doc) => doc.exists() && doc.data())
    .catch((error) => {
      console.error(
        "Error reading document",
        `${collectionName}/${uid}`,
        JSON.stringify(error)
      );
    });

  if (docData) {
    docData.title += " (copy)";
    if (docData.slug) docData.slug += "-copy";
    if (docData.secret) docData.secret = true;
    docData.createdAt = new Date();
    docData.updatedAt = new Date();
    try {
      const newDocCollectionRef = collection(
        db,
        `users/${uid}/${collectionName}`
      );
      const newDocRef = await addDoc(newDocCollectionRef, {
        ...omit(docData, ["id"]),
      });

      // We store an id field inside the document. Not sure why.
      // This is not good practice, but we don't know what would break if we remove it.
      const newId = newDocRef.id;
      await updateDoc(newDocRef, { id: newId });

      return newDocRef.id;
    } catch (error) {
      console.error(
        "Error creating document",
        `${collection}/${uid}`,
        JSON.stringify(error)
      );
    }
  }
};
