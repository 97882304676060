import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const EmbedIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.57107 12.3639L9.63173 16.4246C9.82699 16.6199 9.82699 16.9364 9.63173 17.1317L8.92462 17.8388C8.72936 18.0341 8.41278 18.0341 8.21752 17.8388L3.14645 12.7677C3.03602 12.6573 2.98804 12.5081 3.00252 12.3639C2.98804 12.2198 3.03602 12.0706 3.14645 11.9601L8.21751 6.88906C8.41278 6.6938 8.72936 6.6938 8.92462 6.88906L9.63173 7.59617C9.82699 7.79143 9.82699 8.10801 9.63173 8.30327L5.57107 12.3639Z"
        fill="#040506"
      />
      <path
        d="M18.2071 12.3639L14.1464 8.30325C13.9512 8.10798 13.9512 7.7914 14.1464 7.59614L14.8535 6.88903C15.0488 6.69377 15.3654 6.69377 15.5607 6.88903L20.6317 11.9601C20.7421 12.0705 20.7901 12.2198 20.7757 12.3639C20.7901 12.508 20.7422 12.6573 20.6317 12.7677L15.5607 17.8388C15.3654 18.034 15.0488 18.034 14.8535 17.8388L14.1464 17.1317C13.9512 16.9364 13.9512 16.6198 14.1464 16.4246L18.2071 12.3639Z"
        fill="#040506"
      />
    </svg>
  );
};

export default EmbedIcon;
