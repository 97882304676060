import { FC, useEffect, useState } from "react";
import axios from "axios";
import { compact, flatten, orderBy, uniq } from "lodash";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import { useCollection } from "@contexts/data";
import { useHighlightedTextContext } from "@contexts/highlighted";
import { useHasAnyClient } from "@hooks/data/clients";
import useAccessType from "@hooks/use-access-type";
import { useUnreadChannels } from "@hooks/use-chat";
import useContactBatchArchive from "@hooks/use-contact-batch-archive";
import { useContactSearch } from "@hooks/use-contact-search";
import useDebounce from "@hooks/use-debounce";
import useGroups from "@hooks/use-groups";
import { useListConfiguration } from "@hooks/use-list-configuration";
import useMemberFilterOptions from "@hooks/use-member-filter-options";
import useSnackbar from "@hooks/use-snackbar";
import { useIsMobile } from "@hooks/use-window-size";
import { useUpdateContactsCache } from "@hooks/useUpdateContactsCache";
import analytics from "@lib/analytics";
import { displayNameFromContact } from "@lib/contacts";
import { ClientType } from "@lib/data/schemas/client";
import { sortByDisplayName } from "@lib/sortBy";
import { downloadCSVFile } from "@lib/utils";
import pluralHelper from "@lib/utils/pluralHelper";

import AppFrame from "@components/App/AppFrame";
import Contact from "@components/Contact";
import EmptyList from "@components/EmptyList";
import EmptyListLarge from "@components/EmptyListLarge";
import ClientsIllustrationEmptyState from "@components/EmptyState/ClientsIllustrationEmptyState";
import ClientsIntegrationEmptyState from "@components/EmptyState/ClientsIntegrationEmptyState";
import GroupsList from "@components/Groups/GroupsList";
import ArchiveIcon from "@components/Icons/ArchiveIcon";
import ClientIcon from "@components/Icons/ClientIcon";
import ContactAddIcon from "@components/Icons/ContactAddIcon";
import ContactIcon from "@components/Icons/ContactIcon";
import DownloadIcon from "@components/Icons/DownloadIcon";
import GroupIcon from "@components/Icons/GroupIcon";
import LabelIcon from "@components/Icons/LabelIcon";
import LiveChatIcon from "@components/Icons/LiveChatIcon";
import RippleIcon from "@components/Icons/RippleIcon";
import TeamIcon from "@components/Icons/TeamIcon";
import TextboxIcon from "@components/Icons/TextboxIcon";
import UnarchiveIcon from "@components/Icons/UnarchiveIcon";
import { LabelsSelector, useLabelsSelectorBatch } from "@components/Labels";
import LoadingSpinner from "@components/LoadingSpinner";
import AboutModal from "@components/Modals/AboutModal";
import MultipleActions from "@components/MultipleActions";
import { ListSearchBar } from "@components/SearchBar";
import {
  SelectableList,
  SelectableListContextProvider,
  useSelectableListContext,
} from "@components/SelectableList";
import { FilterByItemType } from "@components/SortDropdown";
import Tabs from "@components/Tabs/Tabs";

const CONTACT_SORT_OPTIONS = [
  {
    icon: <RippleIcon />,
    text: "Activity",
    value: "activity",
    transform: (items: any) =>
      orderBy(items, (item) => item.latestMergedActivity?.date ?? 0, ["desc"]),
  },
  {
    icon: <TextboxIcon />,
    text: "A-Z",
    value: "name",
    transform: sortByDisplayName,
  },
];

const CONTACT_FILTER_OPTIONS = [
  {
    text: "Show archived",
    transform: (items: any, value: any) =>
      items.filter((item) => value || item.status !== "archived"),
    key: "showArchived",
    defaultValue: false,
  },
];

export const updateContactStatus = async (
  userId: string,
  contactId: string,
  status: string,
  callback?: () => void
): Promise<void> => {
  await axios.put(`/api/v1/users/${userId}/clients/${contactId}`, { status });
  analytics.track(
    status === "archived" ? "archive_client" : "unarchive_client",
    { method: "web" }
  );
  if (callback) {
    callback();
  }
  return;
};

const GroupsTabContent: FC<{
  canEditGroups: boolean;
  showMembersFilter?: boolean;
}> = ({ canEditGroups, showMembersFilter = false }) => {
  const { oid } = useAuth();
  const { activeGroups: groups, loading } = useGroups(oid);

  if (loading) return <LoadingSpinner />;

  return (
    <GroupsList
      groups={groups}
      canEdit={canEditGroups}
      showMembersFilter={showMembersFilter}
    />
  );
};

interface ClientsTabContentProps {
  clientType?: ClientType["clientType"];
}

const ClientsTabContent: FC<ClientsTabContentProps> = ({ clientType }) => {
  const { oid, isOwner } = useAuth();
  const { data: labels } = useCollection("labels");
  const { query } = useRouter();
  const snackbar = useSnackbar();
  const isMobile = useIsMobile();
  const { permissions } = useAccessType();
  const { options: memberFilterOptions } = useMemberFilterOptions();
  const [sort, setSort] = useState<"name" | "activity">("name");
  const [assigneeId, setAssigneeId] = useState<string | undefined>(undefined);

  const {
    selected: selectedContacts,
    selectedIds,
    totalSelected,
    clearSelected,
  } = useSelectableListContext();

  const {
    highlight: searchTerm,
    resetSearch,
    setHighlight,
  } = useHighlightedTextContext();

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const {
    data: clients,
    loading,
    loadMore,
    mutate,
  } = useContactSearch(oid, sort, {
    searchKey: debouncedSearchTerm,
    clientType,
    assigneeId,
  });

  const updateAssigneeIdIfNecessary = (filterValue: string) => {
    if (filterValue === "all" && assigneeId !== undefined) {
      setAssigneeId(undefined);
    }

    if (filterValue !== "all" && filterValue !== assigneeId) {
      setAssigneeId(filterValue);
    }
  };

  const {
    SortDropdown,
    data: filteredItems,
    selectedSort,
  } = useListConfiguration(
    clients,
    CONTACT_SORT_OPTIONS,
    [
      ...(memberFilterOptions && permissions?.canViewAllContacts
        ? ([
            {
              text: "Visibility",
              transform: (items: any[], value: string) => {
                updateAssigneeIdIfNecessary(value);
                return items;
              },
              options: memberFilterOptions,
              key: "clientsVisibility",
              defaultValue: "all",
            },
          ] as FilterByItemType[])
        : []),
      ...CONTACT_FILTER_OPTIONS,
    ],
    "contactsConfiguration"
  );

  useEffect(() => {
    setSort(selectedSort);
  }, [selectedSort]);

  useEffect(() => {
    if (query.labelId && !searchTerm && !loading) {
      const defaultLabel = labels?.find((label) => label.id === query.labelId);
      if (defaultLabel) {
        setHighlight(defaultLabel.title);
      }
    }
  }, [query, labels, searchTerm, setHighlight, loading]);

  const { updateCache } = useUpdateContactsCache();

  const { update: archiveContacts } = useContactBatchArchive({
    userId: oid ?? "",
  });
  const {
    selectorIsOpen,
    setSelectorIsOpen,
    toggleLabel,
    createLabel,
    updating,
  } = useLabelsSelectorBatch({
    userId: oid ?? "",
  });

  const toggleArchive = (contact: ClientType) => {
    const isArchived = contact.status === "archived";
    const status = isArchived ? "active" : "archived";
    updateContactStatus(oid ?? "", contact.id, status, () => {
      const args = isArchived
        ? [`Contact unarchived`, null]
        : [
            "Contact archived",
            `Bye bye ${displayNameFromContact(contact)}`,
            "Undo",
            () => updateContactStatus(oid ?? "", contact.id, "active", mutate),
          ];
      updateCache([{ contactId: contact.id, data: { status } }]);
      snackbar.showMessage(...args);
    });
  };

  const getContactLabelIds = () => {
    const contacts = clients?.filter((contact) =>
      selectedIds.includes(contact.id)
    );

    const byContact =
      contacts?.map((contact) => ({
        id: contact.id,
        labels: contact?.labels || [],
      })) || [];
    const labelIds = uniq(flatten(contacts?.map((contact) => contact?.labels)));
    return { labelIds, byContact };
  };

  const handleToggleLabel = (label) => {
    const contact = getContactLabelIds();
    toggleLabel({
      label,
      contactIds: selectedIds,
      selectedLabels: contact.labelIds,
      byContact: contact.byContact,
    });
  };

  const handleCreateLabel = (data) => {
    const contact = getContactLabelIds();
    createLabel({
      data,
      contactIds: selectedIds,
      byContact: contact.byContact,
      selectedLabels: contact.labelIds,
    });
  };

  const handleClickBackdrop = (value) => {
    setSelectorIsOpen(value);
  };

  const handleMultipleLabels = () => {
    setSelectorIsOpen(true);
  };

  const archiving = selectedContacts.some(
    (client) => client.status !== "archived"
  );

  const handleMultipleArchive = async () => {
    const statusTo = archiving ? "archived" : "active";
    const statusFrom = archiving ? "active" : "archived";
    const archiveStr = archiving ? "archived" : "unarchived";

    await archiveContacts({ contactIds: selectedIds, status: statusTo });
    clearSelected();
    snackbar.showMessage(
      `Clients ${archiveStr}`,
      "",
      "Undo",
      async () =>
        await archiveContacts({ contactIds: selectedIds, status: statusFrom })
    );
  };

  const handleClickLabel = (label) => {
    setHighlight(label);
  };

  const { unreadChannels } = useUnreadChannels();

  const renderMultipleAction = (
    <MultipleActions
      label={`${pluralHelper(totalSelected, "client")} selected`}
      actions={[
        {
          icon: <LabelIcon className="text-white" />,
          onClick: handleMultipleLabels,
        },
        {
          icon: archiving ? (
            <ArchiveIcon className="text-peach-600" />
          ) : (
            <UnarchiveIcon className="text-peach-600" />
          ),
          onClick: handleMultipleArchive,
          clearSelection: true,
        },
      ]}
    />
  );

  const emptyListText = isOwner
    ? "Clients will show up as you import or manually add contacts."
    : "There are no contacts assigned to you yet.";

  return (
    <>
      <div className="flex mt-6 items-center">
        <ListSearchBar />
        <div className="ml-8">{SortDropdown}</div>
      </div>
      <div className="pb-6 mt-8 mb-6">
        <SelectableList
          loadByScreenSize={false}
          items={filteredItems}
          isLoading={loading}
          selectable={!isMobile}
          filterInPlace={false}
          emptyList={
            <EmptyList
              icon={<ClientIcon />}
              description={emptyListText}
              hideButton
            />
          }
          emptySearch={
            <EmptyListLarge
              title="Nothing there..."
              description={`Looks like you don't have any clients including "${searchTerm}"`}
              actionButtonHandler={resetSearch}
              actionTitle="Reset filters"
            />
          }
          rowRenderer={(contact) => (
            <Contact
              key={contact.id}
              contact={contact}
              toggleArchive={toggleArchive}
              displayNotification={!!unreadChannels[contact.id]}
              onClickLabel={handleClickLabel}
              userId={oid ?? ""}
              selectable={!isMobile}
              checked={selectedIds.includes(contact.id)}
            />
          )}
          trigger={{
            onIntersection: () => loadMore(),
          }}
        />
      </div>
      {renderMultipleAction}
      <LabelsSelector
        isOpen={selectorIsOpen}
        labels={labels}
        labelsSelected={getContactLabelIds().labelIds}
        onClickBackdrop={handleClickBackdrop}
        onClickLabel={handleToggleLabel}
        onClickCreate={handleCreateLabel}
        withBackdrop
        asModal
        updating={updating ?? undefined}
      />
    </>
  );
};

const Contacts = () => {
  const { organization, oid, isOwner } = useAuth();
  const { hasFullAccess, hasElevatedAccess, permissions } = useAccessType();
  const canEditGroups = hasElevatedAccess;
  const canExportClients = hasElevatedAccess;
  const canEditClientTemplate = hasFullAccess;
  const showMembersFilter = permissions?.canViewAllContacts || false;

  const { hasAnyClient: hasClients } = useHasAnyClient();
  const { hasAnyClient: hasCompanies } = useHasAnyClient(undefined, "company");
  const { hasAnyClient: hasFamilies } = useHasAnyClient(undefined, "family");

  const snackbar = useSnackbar();

  const handleDownloadCSV = () => {
    setIsDownloadingCSV(true);
    fetch(`/api/v1/users/${oid}/download-clients`)
      .then((res) => {
        if (res.status === 500) {
          throw new Error("There was an error downloading your CSV file.");
        }

        return res.text();
      })
      .then((csvText) => {
        if (csvText) {
          const coachName = displayNameFromContact(organization);
          // Nice filename
          const filename = `${coachName} clients Practice.csv`
            .split(" ")
            .join("-");

          downloadCSVFile(csvText, filename);
          setIsDownloadingCSV(false);
        }
      })
      .catch(() => {
        const contactSupport = () => {
          window.location.href = "mailto:support@practice.do";
        };

        // If there's an error, show a snackbar saying so and giving the option
        // for the user to contact Support
        snackbar.showMessage(
          "There was an error downloading your CSV file.",
          "Please contact support@practice.do",
          "Contact",
          () => contactSupport()
        );
        setIsDownloadingCSV(false);
      });
  };

  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);

  const [showAboutModal, setShowAboutModal] = useState<boolean>(false);

  const handleLearnMore = () => setShowAboutModal(true);

  return (
    <AppFrame
      variant="main"
      title="Contacts"
      actions={
        hasElevatedAccess
          ? compact([
              {
                icon: <ContactAddIcon />,
                text: "Create a client",
                href: "/contacts/create",
              },
              {
                text: "Create an organization",
                href: "/client-organizations/create",
                icon: <TeamIcon />,
              },
              canEditGroups && {
                text: "Create a group",
                href: "/groups/create",
                icon: <GroupIcon />,
              },
              {
                icon: <ContactIcon />,
                text: "Import clients",
                href: "/contacts/import",
              },
            ])
          : undefined
      }
      headerButtons={compact([
        canEditClientTemplate && {
          icon: <ContactIcon />,
          text: "Client template",
          href: "/contacts/template",
        },
        canExportClients && {
          icon: isDownloadingCSV ? (
            <LoadingSpinner variant="transparent" className="w-6 h-6" />
          ) : (
            <DownloadIcon />
          ),
          text: "Export clients",
          isIconButton: true,
          onClick: handleDownloadCSV,
          heapEventName: "download_clients_csv",
        },
        {
          icon: <LiveChatIcon />,
          isIconButton: true,
          text: "Learn more",
          onClick: handleLearnMore,
        },
      ])}
    >
      {
        // For now we only want to show these empty state info cards to the account owner.
        // Invited members will get a different onboarding experience soon.
        isOwner ? (
          !hasClients ? (
            <ClientsIntegrationEmptyState className="mb-6" />
          ) : (
            <ClientsIllustrationEmptyState className="mb-6" />
          )
        ) : null
      }
      <SelectableListContextProvider>
        <Tabs>
          <Tabs.Item name="Clients">
            <ClientsTabContent clientType="individual" />
          </Tabs.Item>
          <Tabs.Item name="Groups">
            <GroupsTabContent
              canEditGroups={canEditGroups}
              showMembersFilter={showMembersFilter}
            />
          </Tabs.Item>
          {hasFamilies && (
            <Tabs.Item name="Families">
              <ClientsTabContent clientType="family" />
            </Tabs.Item>
          )}
          {hasCompanies && (
            <Tabs.Item name="Companies">
              <ClientsTabContent clientType="company" />
            </Tabs.Item>
          )}
        </Tabs>
      </SelectableListContextProvider>
      <AboutModal
        categoryName="contacts"
        show={showAboutModal}
        toggleShow={setShowAboutModal}
      />
    </AppFrame>
  );
};

export default Contacts;
