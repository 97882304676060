import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.3536 17.8536C12.1583 18.0488 11.8417 18.0488 11.6465 17.8536L6.40383 12.6109C6.20857 12.4157 6.20857 12.0991 6.40383 11.9038L7.11094 11.1967C7.3062 11.0014 7.62278 11.0014 7.81805 11.1967L11 14.3787V3.5C11 3.22386 11.2239 3 11.5 3H12.5C12.7762 3 13 3.22386 13 3.5V14.3787L16.182 11.1967C16.3773 11.0014 16.6939 11.0014 16.8891 11.1967L17.5962 11.9038C17.7915 12.0991 17.7915 12.4157 17.5962 12.6109L12.3536 17.8536Z" />
      <path d="M4.5 20C4.22386 20 4 20.2239 4 20.5V21.5C4 21.7761 4.22386 22 4.5 22H19.5C19.7761 22 20 21.7761 20 21.5V20.5C20 20.2239 19.7761 20 19.5 20H4.5Z" />
    </svg>
  );
};

export default Icon;
