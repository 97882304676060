import { useEffect, useState } from "react";

export default function useResize() {
  const [slice, setSlice] = useState([0, 6, 6, 9]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767 && window.innerWidth > 640)
        setSlice([0, 4, 4, 6]);
      if (window.innerWidth < 640) setSlice([0, 3, 3, 4]);
      if (window.innerWidth > 767) setSlice([0, 6, 6, 9]);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return slice;
}
