import React, { useMemo, useState } from "react";
import _ from "lodash";
import router from "next/router";

import { useAuth } from "@contexts/auth";
import { useHighlightedTextContext } from "@contexts/highlighted";
import { useListConfiguration } from "@hooks/use-list-configuration";
import useResize from "@hooks/use-resize";
import useSnackbar from "@hooks/use-snackbar";
import { duplicateArtifact } from "@lib/duplicate";
import { COMMON_SORT_OPTIONS, getStatusFilter } from "@lib/sortBy";
import { getArtifactLink } from "@lib/utils/artifacts";

import SharingModal from "@components/Modals/SharingModal";

import SectionActionCards from "./Layout/SectionActionCards";
import ContentBox from "./ContentBox";
import ContentBoxAddButton from "./ContentBoxAddButton";
import EmptyListLarge from "./EmptyListLarge";
import { FilterByItemType } from "./SortDropdown";
import TypecardsCollapse from "./TypecardsCollapse";

interface Props {
  collection: string;
  artifactType: string;
  items: Array<Record<string, unknown>> | undefined;
  onDelete?: (id: string) => void;
  onUndoDelete: (undoStatus: string, itemId?: string) => Promise<void>;
  undoStatus: string;
  deleteDialogSubtitle?: string;
  highlightedId?: string;
  onActiveChange?: ({
    id,
    activity,
  }: {
    id: string;
    activity: string;
  }) => Promise<void>;
  defaultItemIcon?: string;
  itemTitle: (item: Record<string, unknown>) => string | JSX.Element;
  itemSubtitle: (item: Record<string, unknown>) => string | JSX.Element;
  topText?: (item: Record<string, unknown>) => React.ReactNode;
  showDuplicate?: boolean;
  showCopy?: boolean;
  showPreview?: boolean;
  showEdit?: boolean;
  showSend?: boolean;
  title?: string;
  clickGoesToView?: boolean;
  showSearch?: boolean;
  showMoreInfoButton?: boolean;
  showCreateIfEmpty?: boolean;
  extraFilterOptions?: FilterByItemType[];
}

const UserItemList: React.FC<Props> = ({
  collection,
  artifactType,
  items,
  onDelete,
  onUndoDelete,
  undoStatus,
  deleteDialogSubtitle,
  highlightedId,
  onActiveChange,
  defaultItemIcon,
  itemTitle,
  itemSubtitle,
  topText,
  showDuplicate = false,
  showCopy = false,
  showPreview = false,
  showEdit = false,
  showSend = false,
  showSearch = false,
  title,
  clickGoesToView = false,
  showMoreInfoButton = true,
  showCreateIfEmpty = true,
  extraFilterOptions,
}) => {
  const { firestoreUser, oid } = useAuth();

  const isGroup = artifactType === "group";

  const {
    SortDropdown,
    data: shownItems,
    collapsed,
    setCollapsed,
  } = useListConfiguration(
    items,
    COMMON_SORT_OPTIONS,
    [
      ...(extraFilterOptions ? extraFilterOptions : []),
      getStatusFilter(
        `Show ${isGroup ? "archived" : "inactives"}`,
        "inactive",
        "showArchived",
        false
      ),
    ],
    `${collection}ListConfiguration`
  );

  const snackbar = useSnackbar();
  const [shareModalVisible, setshareModalVisible] = useState(false);
  const [itemLink, setItemLink] = useState("");

  const slice = useResize();

  // @TODO: fix type
  const sendToClient = (link: string, item: any) => {
    setshareModalVisible(item);
    setItemLink(link);
  };

  // @TODO: fix types
  const renderItem = (item: any, fade: any) => {
    const { id, icon, status } = item;
    const itemLink = `${collection}/${encodeURIComponent(id)}`;
    const copyLink = getArtifactLink(firestoreUser, item, collection);

    const onDuplicate = async () => {
      const itemId = await duplicateArtifact({
        collectionName: collection,
        id,
        uid: oid!,
      });
      router.push(`/${collection}/${itemId}`);
      snackbar.showMessage(
        `"${item.title}" duplicated!`,
        "Next edit and save your new item."
      );
    };

    return (
      <ContentBox
        id={id}
        key={id}
        highlighted={item.id === highlightedId}
        onDelete={onDelete}
        onUndoDelete={onUndoDelete}
        undoStatus={undoStatus}
        dialogSubtitle={deleteDialogSubtitle}
        fade={fade}
        icon={icon ?? defaultItemIcon ?? "ill_heart"}
        iconFileType="svg"
        title={itemTitle(item)}
        subtitle={itemSubtitle(item)}
        topText={topText && topText(item)}
        tooltip={
          showEdit
            ? undefined
            : `Please contact your admin to modify this ${artifactType}.`
        }
        editLink={
          showEdit
            ? clickGoesToView
              ? `/${itemLink}/edit`
              : `/${itemLink}`
            : ""
        }
        artifactViewLink={clickGoesToView ? `/${itemLink}` : undefined}
        onDuplicate={showDuplicate ? onDuplicate : undefined}
        onActiveChange={onActiveChange}
        copyLink={showCopy ? copyLink : undefined}
        previewLink={showPreview ? copyLink : undefined}
        type={_.capitalize(artifactType)}
        status={status}
        onSendToClient={
          showSend ? () => sendToClient(copyLink, item) : undefined
        }
        secret={item.secret}
        showMoreInfoButton={showMoreInfoButton}
      />
    );
  };

  const { filter, highlight, resetSearch } = useHighlightedTextContext();

  const filtered = useMemo(() => filter(shownItems), [filter, shownItems]);
  const expandWithClientId =
    artifactType === "package" || artifactType === "product";
  return (
    <div>
      {shownItems && (
        <SectionActionCards
          SortDropdown={SortDropdown}
          showSearch={showSearch}
          title={title ? title : ""}
        >
          {!!shownItems?.length && !filtered?.length && (
            <EmptyListLarge
              containerClassName="mx-auto col-span-full mt-4"
              title="Nothing there..."
              description={`Looks like you don't have any groups including "${highlight}"`}
              actionButtonHandler={resetSearch}
              actionTitle="Reset filters"
            />
          )}
          {filtered?.length > 0
            ? !collapsed
              ? filtered.map((item) => renderItem(item, false))
              : filtered
                  .slice(slice[0], slice[1])
                  .map((item) => renderItem(item, false))
            : shownItems.length === 0 &&
              showCreateIfEmpty && (
                <ContentBoxAddButton
                  text={`Create ${artifactType}`}
                  href={`/${collection}/create`}
                />
              )}
        </SectionActionCards>
      )}
      <TypecardsCollapse
        type={collection}
        handleSetCollapsed={setCollapsed}
        slice={slice}
        renderType={renderItem}
        collapsed={collapsed}
        shownArray={shownItems}
      />
      <div key={itemLink}>
        <SharingModal
          artefactType={artifactType}
          artefactLink={itemLink}
          artefactItem={shareModalVisible}
          body={`Please use this link to access your ${artifactType}:\n{{link}}`}
          toggleShow={setshareModalVisible}
          show={!!shareModalVisible}
          expandWithClientId={expandWithClientId}
        />
      </div>
    </div>
  );
};

export default UserItemList;
