import React, { FC } from "react";
import Link from "next/link";

import PlusIcon from "@components/Icons/PlusIcon";

interface ContentBoxAddButtonProps {
  text: string;
  href?: string;
  onClick?: () => void;
}

const ContentBoxAddButton: FC<ContentBoxAddButtonProps> = ({
  text,
  href,
  onClick,
}) => {
  const content = (
    <>
      <PlusIcon className="w-7 h-7 mb-2" />
      <p className="text-left truncate text-black-ink font-medium">{text}</p>
    </>
  );

  return (
    <div className="relative inline-block">
      <div className="box-content h-full">
        <div className="flex col-span-1 rounded-md h-full">
          <div className="flex items-start justify-between flex-1 truncate bg-white border h-26 border-grey-900 hover:bg-grey-900 rounded-md">
            <div className="flex-1 truncate">
              {href && (
                <Link href={href} className="inline-block w-full h-full p-4">
                  {content}
                </Link>
              )}
              {onClick && (
                <button
                  onClick={onClick}
                  className="inline-block w-full h-full p-4"
                >
                  {content}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentBoxAddButton;
